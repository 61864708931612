import * as React from 'react'
import { useDispatch } from 'react-redux'
import ScrollAnimation from 'react-animate-on-scroll'
import { FiArrowRight } from 'react-icons/fi'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { set } from '../reducers/usecase'
import Layout from '../layouts/layout'
import Title from '../components/section/title'
import Breadcrumb from '../components/section/breadcrumb'
import * as styles from '../styles/css/data.module.css'

const Vision = () => {
  const dispatch = useDispatch();
  const openLayer = e => {
    e.preventDefault()
    dispatch(set(e.target.dataset.param))
  }

  const { t } = useTranslation();
  const image = {
    title: t('imageTitle'),
    body: t('imageDesc'),
    items: [
      t('imageElement1'),
      t('imageElement2'),
      t('imageElement3'),
    ]
  }
  return (
    <Layout>
      <div className="main-content">
        <div className="rwt-split-area">
          <div className="row ptb--0">
            <Breadcrumb
              title={t('visionTitle')}
              rootUrl="/vision"
              parentUrl="Vision"
              currentUrl="Image Analysis Platform"
              styles="pb--15"
            />
          </div>
          <div className="container">
            <div className="wrapper">
              <div className="row">
                <div className="col-lg-12 mb--40">
                  <Title
                    textAlign = "text-center"
                    radiusRounded = ""
                    subtitle = "Vision"
                    title ={image.title}
                    description = ""
                  />
                </div>
              </div>
              {/* first */}
              <div className="rn-splite-style">
                <div className="split-wrapper split-right">
                  <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                        <div className={`thumbnail image-left-content`}>
                        <iframe width="585" height="315"
                        src="https://www.youtube.com/embed/faVIAIE6fM4">
                        </iframe>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                      <div className="split-inner">
                          <p className="h4" dangerouslySetInnerHTML={{__html: image.body}}></p>
                          <ul className="split-list">
                          {image.items.map( (item , i) => (
                            <li key={i}>- {item}</li>
                          ))}
                          </ul>
                        <div className="mt--10">
                          <Link className="btn-default btn-small btn-border btn-icon" to="/vision">Back to Vision <i className="icon"><FiArrowRight /></i></Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default Vision